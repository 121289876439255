.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
}

/* Disable pointer events on the overlay and content */
.modal-overlay,
.modal-content {
  pointer-events: none;
}

/* Enable pointer events only on the button */
.modal-content button {
  pointer-events: auto;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

/* :root {
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
} */

/* body {
  background-color: var(--bg);
  color: var(--fg);
  font: 1em/1.5 sans-serif;
  height: 100vh;
  display: grid;
  place-items: center;
  transition: background-color var(--trans-dur);
}

main {
  padding: 1.5em 0;
} */

main {
  padding: 2em 0;
}

.ip {
  width: 4em;
  height: 2em;
  position: fixed;
  top: 35%;
  left: 51%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  --hue: 223;
  --bg: hsl(var(--hue), 90%, 95%);
  --fg: hsl(var(--hue), 90%, 5%);
  --trans-dur: 0.3s;
  font-size: calc(16px + (24 - 16) * (100vw - 320px) / (1280 - 320));
}

.ip__track {
  stroke: hsl(var(--hue), 90%, 90%);
  transition: stroke var(--trans-dur);
}

.ip__worm1,
.ip__worm2 {
  animation: worm1 2s linear infinite;
}

.ip__worm2 {
  animation-name: worm2;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
  :root {
    --bg: hsl(var(--hue), 90%, 5%);
    --fg: hsl(var(--hue), 90%, 95%);
  }

  .ip__track {
    stroke: hsl(var(--hue), 90%, 15%);
  }
}

/* Animation */
@keyframes worm1 {
  from {
    stroke-dashoffset: 0;
  }

  50% {
    animation-timing-function: steps(1);
    stroke-dashoffset: -358;
  }

  50.01% {
    animation-timing-function: linear;
    stroke-dashoffset: 358;
  }

  to {
    stroke-dashoffset: 0;
  }
}

@keyframes worm2 {
  from {
    stroke-dashoffset: 358;
  }

  50% {
    stroke-dashoffset: 0;
  }

  to {
    stroke-dashoffset: -358;
  }
}

/*THIRD LOADER*/

#loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -2.7em;
  margin-left: -2.7em;
  width: 5.4em;
  height: 5.4em;
  background-color: #404456;
}

#hill {
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  top: 1.7em;
  left: 1.7em;
  background-color: transparent;
  border-left: 0.25em solid whitesmoke;
  transform: rotate(45deg);
}

#hill:after {
  content: '';
  position: absolute;
  width: 7.1em;
  height: 7.1em;
  left: 0;
  background-color: #404456;
}

#box {
  position: absolute;
  left: 0;
  bottom: -0.1em;
  width: 1em;
  height: 1em;
  background-color: transparent;
  border: 0.25em solid whitesmoke;
  border-radius: 15%;
  transform: translate(0, -1em) rotate(-45deg);
  animation: push 2.5s cubic-bezier(0.79, 0, 0.47, 0.97) infinite;
}

@keyframes push {
  0% {
    transform: translate(0, -1em) rotate(-45deg);
  }
  5% {
    transform: translate(0, -1em) rotate(-50deg);
  }
  20% {
    transform: translate(1em, -2em) rotate(47deg);
  }
  25% {
    transform: translate(1em, -2em) rotate(45deg);
  }
  30% {
    transform: translate(1em, -2em) rotate(40deg);
  }
  45% {
    transform: translate(2em, -3em) rotate(137deg);
  }
  50% {
    transform: translate(2em, -3em) rotate(135deg);
  }
  55% {
    transform: translate(2em, -3em) rotate(130deg);
  }
  70% {
    transform: translate(3em, -4em) rotate(217deg);
  }
  75% {
    transform: translate(3em, -4em) rotate(220deg);
  }
  100% {
    transform: translate(0, -1em) rotate(-225deg);
  }
}

/* ********* */


/* fourth */

.loader {
  fill: #4570B4;
}

.animated-polygon-container {
  display: flex;
  justify-content: center;
  align-items: center;

}

.smooth-progress { transition: width 0.4s ease}

.GptPage {
  display: grid;
  grid-template-columns: auto 200px;
  gap: 20px;
  max-width: 1000px;
  margin: 0 auto;
  min-height: 100vh;
  padding: 20px;
}

.GptColumn {
  color: white;
}

.GptTitle {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  color: black;
  background-color: rgb(218, 255, 170);
}

.GptContent {
  height: calc(100vh - 200px);
  overflow-y: scroll;
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  display: none;
}

/* Container uses flexbox to arrange columns */
.threeColContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

/* Each column takes equal space with some margin */
.threeColColumn {
  flex: 1;
  margin: 10px;
  padding: 20px;
  background-color: transparent;
  /* border: 1px solid #ddd; */
  box-sizing: border-box;
}

/* Responsive: stack columns on screens narrower than 768px */
@media (max-width: 768px) {
  .threeColContainer {
    flex-direction: column;
  }
  .threeColColumn {
    margin: 10px 0;
  }
}